import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { Tags } from '@tryghost/helpers-gatsby'
import { readingTime as readingTimeHelper } from '@tryghost/helpers'

const PostCardBanner = ({ post }) => {
    const url = `/${post.slug}/`
    const readingTime = readingTimeHelper(post)

    return (
        <div className="card card_large_with_background grid-item banner">
            {post.feature_image &&
                <div className="card_background" style={{ backgroundImage: `url(${post.feature_image})` }}></div> }
            <div className="card-body">
                <div className="card-title">
                    <Link to={url}>{post.title}</Link>
                </div>
                <div className="card-text">{post.excerpt}</div>
                <div className="">
                    <Link to={url} className="btn btn-read-more">Leer Más</Link>
                </div>
            </div>
        </div>
    )
}

PostCardBanner.propTypes = {
    post: PropTypes.shape({
        title: PropTypes.string.isRequired,
        feature_image: PropTypes.string,
        featured: PropTypes.bool,
        tags: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
            })
        ),
        excerpt: PropTypes.string.isRequired,
        primary_author: PropTypes.shape({
            name: PropTypes.string.isRequired,
            profile_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
}

export default PostCardBanner
