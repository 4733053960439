import React from 'react'

export default class Scrollable extends React.Component {
    state = { x: 0, y: 0 };

    componentDidMount() {
        window.addEventListener(`scroll`, this.handleScroll)
    }

    componentWillUnmount() {
        window.removeEventListener(`scroll`, this.handleScroll)
    }

    handleScroll = () => {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
        const elements = document.getElementsByClassName(`header`)
        for (let i = 0; i < elements.length; i++) {
            const el = elements.item(i)
            if (scrollTop > 100) {
                el.classList ? el.classList.add(`scrolled`) : el.className += ` scrolled`
            } else {
                el.classList ? el.classList.remove(`scrolled`) : el.className.replace(` scrolled`, ``)
            }
        }
    };

    render() {
        return (<></>)
    }
}