import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { Layout, Carousel, PostCard, PostCardBanner, PostCardSide } from '../components/common'
import Masonry from 'react-masonry-component'
import { MetaData } from '../components/common/meta'

/**
* Main index page (home page)
*
* Loads all posts from Ghost and uses pagination to navigate through them.
* The number of posts that should appear per page can be setup
* in /utils/siteConfig.js under `postsPerPage`.
*
*/
const Index = ({ data, location, pageContext }) => {
    const posts = data.posts.edges
    const recommended = data.recommended.edges
    const banner = data.banner.edges.length > 0 ? data.banner.edges[0].node : null

    let featuredPost = posts.find(p => p.featured)
    if (featuredPost === undefined) {
        featuredPost = posts.length > 0 ? posts[0] : undefined
    }

    const masonryOptions = {
        itemSelector: `.grid-item`,
        columnWidth: `.card_small_with_image`,
        gutter: 30,
    }
    const masonryOptionsBanner = {
        itemSelector: `.grid-item`,
        columnWidth: `.card_large_with_background`,
        gutter: 30,
    }
    return (
        <>
            <MetaData location={location} />
            <Layout isHome={true}>
                <div className="home">
                    <div className="home_slider_container">
                        <Carousel featured={featuredPost}></Carousel>
                    </div>
                </div>

                <div className="page_content">
                    <div className="container">
                        <div className="row row-lg-eq-height">
                            <div className="col-lg-9">
                                <div className="main_content">
                                    <div className="blog_section">
                                        {/*<div className="section_panel d-flex flex-row align-items-center justify-content-start">
                                            <div className="section_title">{`Recomendadas para ti`}</div>
                                            <div className="section_tags ml-auto"></div>
                                        </div>*/}
                                        <div className="section_content pt-0">
                                            <Masonry className={`grid clearfix`} options={masonryOptions}>
                                                {posts.map(({ node }) => (
                                                    // The tag below includes the markup for each post - components/common/PostCard.js
                                                    <PostCard key={node.id} post={node} />
                                                ))}
                                            </Masonry>
                                        </div>
                                    </div>

                                    { banner !== null && 
                                        <div className="blog_section">
                                            <div className="section_panel d-flex flex-row align-items-center justify-content-start">
                                                <div className="section_tags ml-auto"></div>
                                            </div>
                                            
                                            <div className="section_content clearfix mb-4 mt-0 pt-0">
                                                <PostCardBanner key={banner.id} post={banner} />
                                            </div>
                                        </div> }
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="sidebar">
                                    <div className="sidebar_background"></div>
                                    <div className="sidebar_section">
                                        <div className="sidebar_title_container">
                                            <div className="sidebar_title">Te Recomendamos</div>
                                        </div>
                                        <div className="sidebar_section_content">
                                            <div className="sidebar_slider_container">
                                                {recommended.map(({ node }) => (
                                                    <PostCardSide key={node.id} post={node} />
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

Index.propTypes = {
    data: PropTypes.shape({
        posts: PropTypes.object.isRequired,
        recommended: PropTypes.object.isRequired,
        banner: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
}

export default Index

// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination
export const pageQuery = graphql`
  query GhostPostQuery($limit: Int!, $skip: Int!) {
    posts: allGhostPost(
        sort: { order: DESC, fields: [published_at] },
        limit: $limit,
        skip: $skip
    ) {
      edges {
        node {
          ...GhostPostFields
        }
      }
    },
    recommended: allGhostPost(
        sort: { order: DESC, fields: [published_at] },
        limit: 6,
        filter: {tags: {elemMatch: {slug: {eq: "recomendados"}}}}
    ) {
        edges {
            node {
            ...GhostPostFields
            }
        }
    },
    banner: allGhostPage(
        sort: { order: DESC, fields: [published_at] },
        limit: 1,
        filter: {tags: {elemMatch: {slug: {eq: "banners"}}}}
    ) {
        edges {
            node {
            ...GhostPageFields
            }
        }
    },
  }
`
