import { graphql, Link, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import React from 'react'
import Helmet from 'react-helmet'
import { Navigation } from '.'
import config from '../../utils/siteConfig'
import Scrollable from './Scrollable'

// Styles
import '../../styles/bootstrap4/bootstrap.min.css'
import '../../styles/main_styles.css'
import '../../styles/responsive.css'
import '../../styles/custom.css'

import logoSheyla from '../../images/logo-sheyla-blanco.png'
import logoSheylaRojo from '../../images/logo-sheyla-rojo.png'

/**
* Main layout component
*
* The Layout component wraps around each page and template.
* It also provides the header, footer as well as the main
* styles, and meta data for each page.
*
*/
class DefaultLayout extends React.Component {

    constructor(props) {
        super(props)
        this.state = { showMobileMenu: false }
    }

    toggleMobileMenu(close = false) {
        const show = close === true ? false : !this.state.showMobileMenu
        this.setState({ showMobileMenu: show })
    }

    render() {
        const { data, children, bodyClass, isHome } = this.props

        const site = data.allGhostSettings.edges[0].node
        const twitterUrl = site.twitter ? `https://twitter.com/${site.twitter.replace(/^@/, ``)}` : null
        const facebookUrl = site.facebook ? `https://www.facebook.com/${site.facebook.replace(/^\//, ``)}` : null

        return (
        <>
            <Helmet>
                <html lang={site.lang} />
                <style type="text/css">{`${site.codeinjection_styles}`}</style>
                <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"></link>
                <body className={bodyClass} />
            </Helmet>
            <Scrollable />
            <div className="super_container">
                <header className="header solid">
                    {/* <header className="header scrolled solid"> */}
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="header_content d-flex flex-row align-items-center justify-content-start">
                                    <div className="logo sheyla">
                                        <a href={`https://sheyla.mx`}><img src={logoSheyla} alt="Sheyla" /></a>
                                    </div>
                                    <nav className="main_nav">
                                        <ul>
                                            <li className="active"><Link to={`/`}>Inicio</Link></li>
                                            <li className="active"><Link to={`/tag/introduccion-al-ecommerce`}>Introducción al ecommerce</Link></li>
                                            <li className="active"><Link to={`/tag/mejores-practicas-en-ventas-online`}>Tácticas de Ventas Online</Link></li>
                                            <li className="active"><Link to={`/tag/marketing-digital`}>Marketing Digital</Link></li>
                                            <li className="active"><Link to={`/tag/administra-tu-tienda-online`}>Administra tu Tienda Online</Link></li>
                                        </ul>
                                    </nav>
                                    <div className="hamburger ml-auto menu_mm" onClick={() => this.toggleMobileMenu()}>
                                        <i className="fa fa-bars trans_200 menu_mm" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className={`menu d-flex flex-column align-items-end justify-content-start text-right menu_mm trans_400 ${this.state.showMobileMenu ? `active` : ``}`}>
                    <div className="menu_close_container">
                        <div className="menu_close" onClick={() => this.toggleMobileMenu()}><div>
                        </div><div></div></div>
                    </div>
                    <div className="logo menu_mm">
                        <a href={`https://sheyla.mx`}><img src={logoSheylaRojo} alt="Sheyla" /></a>
                    </div>
                    <nav className="menu_nav">
                        <ul className="menu_mm">
                            <li className="menu_mm" onClick={() => this.toggleMobileMenu(true)}><Link to={`/`}>Inicio</Link></li>
                            <li className="menu_mm" onClick={() => this.toggleMobileMenu(true)}><Link to={`/tag/introduccion-al-ecommerce`}>Introducción al ecommerce</Link></li>
                            <li className="menu_mm" onClick={() => this.toggleMobileMenu(true)}><Link to={`/tag/mejores-practicas-en-ventas-online`}>Tácticas de Ventas Online</Link></li>
                            <li className="menu_mm" onClick={() => this.toggleMobileMenu(true)}><Link to={`/tag/marketing-digital`}>Marketing Digital</Link></li>
                            <li className="menu_mm" onClick={() => this.toggleMobileMenu(true)}><Link to={`/tag/administra-tu-tienda-online`}>Administra tu Tienda Online</Link></li>
                        </ul>
                    </nav>
                </div>
                <main>
                    {/* All the main content gets inserted here, index.js, post.js */}
                    {children}
                </main>

                <footer className="footer">
                    <div className="container">
                        <div className="row row-lg-eq-height">
                            <div className="col-lg-9 order-lg-1 order-2">
                                <div className="footer_content">
                                    <div className="footer_logo">
                                        <a href={`https://sheyla.mx`}><img src={logoSheyla} alt="Sheyla" /></a>
                                    </div>
                                    <h4 className="mt-2">Te ayuda a crear tu tienda online.</h4>
                                    <a href={`https://sheyla.mx?crear=cuenta&plan=intermediate`} className="btn btn-primary btn-create-account mt-2">
                                        Crear Cuenta
                                    </a>
                                    {/* <div className="footer_social">
                                        <ul>
                                            <li className="footer_social_facebook"><a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                                            <li className="footer_social_twitter"><a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                                            <li className="footer_social_pinterest"><a href="#"><i className="fa fa-pinterest" aria-hidden="true"></i></a></li>
                                            <li className="footer_social_vimeo"><a href="#"><i className="fa fa-vimeo" aria-hidden="true"></i></a></li>
                                            <li className="footer_social_instagram"><a href="#"><i className="fa fa-instagram" aria-hidden="true"></i></a></li>
                                            <li className="footer_social_google"><a href="#"><i className="fa fa-google" aria-hidden="true"></i></a></li>
                                        </ul>
                                    </div> */}
                                    <div className="copyright">
                                        Copyright &copy; {new Date().getFullYear()} Muventa.com, S.A.P.I de C.V.
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 order-lg-2 order-1">
                                <div className="subscribe">
                                    <div className="subscribe_background"></div>
                                    <div className="subscribe_content">
                                        <div className="subscribe_title">Suscribir a Newsletter</div>
                                        <form
                                            action="https://sheyla.us20.list-manage.com/subscribe/post?u=78942f27ecb857c270cf7fe09&amp;id=2a4db2e50a"
                                            method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate"
                                            target="_blank" noValidate>
                                            <input type="email" id="mce-EMAIL" name="EMAIL" className="sub_input" placeholder="Tu correo electrónico" required="required" autoComplete="off" />
                                            <button className="sub_button">
                                                <svg version="1.1" id="link_arrow_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                    width="19px" height="13px" viewBox="0 0 19 13" enableBackground="new 0 0 19 13" xmlSpace="preserve">
                                                    <polygon fill="#FFFFFF" points="12.475,0 11.061,0 17.081,6.021 0,6.021 0,7.021 17.038,7.021 11.06,13 12.474,13 18.974,6.5 "/>
                                                </svg>
                                            </button>
                                            <div style={{ position: `absolute`, left: `-5000px` }} ariahidden="true">
                                                <input type="text" name="b_78942f27ecb857c270cf7fe09_2a4db2e50a" tabIndex="-1" value="" readOnly />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </>
        )
    }
}

DefaultLayout.propTypes = {
    children: PropTypes.node.isRequired,
    bodyClass: PropTypes.string,
    isHome: PropTypes.bool,
    data: PropTypes.shape({
        allGhostSettings: PropTypes.object.isRequired,
    }).isRequired,
}

const DefaultLayoutSettingsQuery = props => (
    <StaticQuery
        query={graphql`
            query GhostSettings {
                allGhostSettings {
                    edges {
                        node {
                            ...GhostSettingsFields
                        }
                    }
                }
                file(relativePath: {eq: "ghost-icon.png"}) {
                    childImageSharp {
                        fixed(width: 30, height: 30) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        `}
        render={data => <DefaultLayout data={data} {...props} />}
    />
)

export default DefaultLayoutSettingsQuery
