import Loadable from '@loadable/component'
import { Link } from 'gatsby'
import React from 'react'
import 'react-owl-carousel2/lib/styles.css'
import 'react-owl-carousel2/src/owl.carousel.css'
import 'react-owl-carousel2/src/owl.theme.default.css'
import '../../styles/owl.css'


const OwlCarousel = Loadable(() => import(`react-owl-carousel2`))

const Carousel = ({ featured }) => {
    const post = featured.node
    const url = `/${post.slug}/`

    const options = {
        items: 1,
        autoplay: false,
        loop: true,
        smartSpeed: 1200,
        dots: true,
        // dotsContainer: `.custom_dots_container`,
        nav: false,
    }
    return (
        <OwlCarousel
            className="owl-carousel owl-theme home_slider"
            options={options}
        >
            <div className="owl-slide">
                <div className="home_slider_background" style={{ backgroundImage: `url(${post.feature_image})` }}></div>
                <div className="home_slider_content_container">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="home_slider_content">
                                    {/* <div className="home_slider_item_category trans_200"><a href="category.html" className="trans_200">sport</a></div> */}
                                    <div className="home_slider_item_title">
                                        <h1><Link to={url}>{post.title}</Link></h1>
                                    </div>
                                    <div className="home_slider_item_link">
                                        <Link to={url} className="trans_200">
                                            Seguir Leyendo
                                            <svg version="1.1" id="link_arrow_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                width="19px" height="13px" viewBox="0 0 19 13" enableBackground="new 0 0 19 13" xmlSpace="preserve">
                                                <polygon fill="#FFFFFF" points="12.475,0 11.061,0 17.081,6.021 0,6.021 0,7.021 17.038,7.021 11.06,13 12.474,13 18.974,6.5 " />
                                            </svg>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </OwlCarousel>
    )
}

// Carousel.propTypes = {
//     featured: PropTypes.shape({
//         title: PropTypes.string.isRequired,
//         html: PropTypes.string.isRequired,
//         feature_image: PropTypes.string,
//     }).isRequired,
// }

export default Carousel
