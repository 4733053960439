import { readingTime as readingTimeHelper } from '@tryghost/helpers'
import { Link } from 'gatsby'
import Moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
Moment.locale(`es`)

const PostCard = ({ post }) => {
    const url = `/${post.slug}/`
    const readingTime = readingTimeHelper(post)

    return (
        <div className="card card_small_with_image grid-item">
            {post.feature_image &&
                <img className="card-img-top" src={`${post.feature_image}`} alt={post.title} /> }
            <div className="card-body">
                <div className="card-title card-title-small">
                    <h2><Link to={url}>{post.title}</Link></h2>
                </div>
                <p className="card-text truncate-overflow">{post.excerpt}</p>
                <small className="post_meta">{Moment(post.published_at).format(`MMM DD, YYYY [ a las ] h:mm a`)}</small>
            </div>
        </div>
    )
}

PostCard.propTypes = {
    post: PropTypes.shape({
        title: PropTypes.string.isRequired,
        feature_image: PropTypes.string,
        featured: PropTypes.bool,
        tags: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
            })
        ),
        excerpt: PropTypes.string.isRequired,
        published_at: PropTypes.string,
        primary_author: PropTypes.shape({
            name: PropTypes.string.isRequired,
            profile_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
}

export default PostCard
