import { readingTime as readingTimeHelper } from '@tryghost/helpers'
import { Link } from 'gatsby'
import Moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
Moment.locale(`es`)

const PostCardSide = ({ post }) => {
    const url = `/${post.slug}/`
    const readingTime = readingTimeHelper(post)

    return (
        <div className="side_post">
            <Link to={url}>
                <div className="d-flex flex-row align-items-xl-center align-items-start justify-content-start">
                    {post.feature_image &&
                        <div className="side_post_image"><div><img src={`${post.feature_image}`} alt={post.title} /></div></div> }
                    <div className="side_post_content">
                        <div className="side_post_title"><h3>{post.title}</h3></div>
                        <small className="post_meta">{Moment(post.published_at).format(`MMM DD`)}</small>
                    </div>
                </div>
            </Link>
        </div>
    )
}

PostCardSide.propTypes = {
    post: PropTypes.shape({
        title: PropTypes.string.isRequired,
        feature_image: PropTypes.string,
        featured: PropTypes.bool,
        tags: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
            })
        ),
        excerpt: PropTypes.string.isRequired,
        published_at: PropTypes.string,
        primary_author: PropTypes.shape({
            name: PropTypes.string.isRequired,
            profile_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
}

export default PostCardSide
